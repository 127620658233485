import React from 'react'
import Footer from '../components/Footer/Footer'
import Navbar from "../components/Navbar/Navbar"
import UslugiPages from "../components/Uslugi/UslugiPages"
import {BoxTextPages, BigTitlePages,  Flex, FlexContent, TitleServices, ListServices, BoxImages} from '../components/Uslugi/UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"

import { theme } from "../components/theme.js";
import { ThemeProvider } from "styled-components"


import SEO from "../components/seo"

const interna = () => {
  return (

    <>
    <ThemeProvider theme={theme}>
    <SEO 
   title="Interna"
   description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący leczenie chorób układów pokarmowego, oddechowego, rozrodczego, moczowego psów, kotów, zwierząt futerkowych i gryzoni. 
    Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
    keywords="pogotowie weterynaryjne kalisz"
    />
    
    <Navbar />
    <BigTitlePages style={{justifyContent: 'center'}}
         whileInView={{y: 0, opacity:1}}
         initial={{ y:50, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Interna</BigTitlePages>  
         
         <FlexContent>
          <UslugiPages />
 <Flex>

 

    <BoxTextPages>

<TitleServices>Diagnozujemy i leczymy:
</TitleServices>
<ListServices> Choroby przewodu pokarmowego, trzustki, wątroby</ListServices>
<ListServices> Choroby układu oddechowego</ListServices>
<ListServices> Choroby układu moczowego</ListServices>
<ListServices> Choroby układu sercowo-naczyniowego</ListServices>
<ListServices> Choroby układu rozrodczego
</ListServices>
<ListServices> Choroby zakaźne</ListServices>
<ListServices> Choroby endokrynologiczne
</ListServices>
<ListServices> Kontrola i monitoring zwierząt w przebiegu cukrzycy
</ListServices>

    </BoxTextPages>
<BoxImages>
<StaticImage
    src="../images/interna.jpeg"
    alt="Usługi internistyczne Kalisz"
    width={600}
    placeholder="blurred"
    className='staticimagepages'
 
    />
 </BoxImages>
    
</Flex>
</FlexContent>
<Footer />
</ThemeProvider>
    </>
  )
}

export default interna